import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    shebei:JSON.parse(localStorage.getItem('myshebei') || JSON.stringify([])),xuanzhongindex:0
  },
  getters: {
    // xuanzhong:
    xuanzhong: state => {
      return (state.shebei.find(item => item.act)&&state.shebei.find(item => item.act)['position'])||state.shebei[0]['position']
    } ,xuanzhongname: state => {
      return (state.shebei.find(item => item.act)&&state.shebei.find(item => item.act)['location'])||state.shebei[0]['location']
    }
   
  },
  mutations: {
    setshebei(state,i){
      state.shebei = i
    },
    setshebeiindex(state,i){
      state.xuanzhongindex = i
    }
  },
  actions: {
  },
  modules: {
  }
})
