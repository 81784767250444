<template>
   <div class="haxi">
      <table :style="tableStyle">
         <thead>
            <tr :style="{height:50+'px'}">
               <th :style="{ width: firstColumnWidth, backgroundColor: '#CCE5FF' }"></th>
               <th
                  v-for="(label, index) in toushuju"
                  :key="label+index"
                  :style="{width: columnWidth, backgroundColor: '#CCE5FF'}"
               >
                  <!-- {{label}} -->
                  {{ label }}
               </th>
            </tr>
         </thead>
         <tbody>
            <tr
               v-for="(row, index) in rshuju"
               v-show="!isduo ||myxz.includes(datahead[index])"
               :style="{height:trhei+'px'}"
               :key="index+(datahead[index])"
            >
               <td v-html="adddw(lietou[index]) " :style="{backgroundColor: getRowColor(index)}"></td>
               <td
                  v-for="n in row"
                  :key="getCurrentTimestamp()+index+n"
                  :style="{backgroundColor: getRowColor(index)}"
               >
                  <!-- {{datahead[index]+n}} -->
                  {{nha(n ==null?'无' : n) }}
               </td>
            </tr>
         </tbody>
      </table>
   </div>
</template>

<script>
export default {
   props: {
      data: Array,
      toudata: {
         default: () => {
            return {};
         },
         type: [Array, Object]
      },
      datahead: {
         default: () => {
            return [""];
         },
         type: [Array, Object]
      },
      styleType: {
         type: Number,
         default: 1 // 1 or 2
      },
      trhei: {
         type: Number,
         default: 90 // 1 or 2
      },
      myxz: {
         default: () => [],
         type: Array
      },
      isduo: {
         default: false,
         type: Boolean || String
      }
   },
   computed: {
      haxis() {
         return Object.keys(this.toudata).slice(1);
      },
      toushuju() {
         if (!this.toudata) {
            return [];
         }
         let k = JSON.parse(JSON.stringify(this.toudata));
         return this.$chuli([k]);
      },
      lietou() {
         if (!this.datahead) {
            return [];
         }
         // console.log('this.datahead :>> ', this.datahead);
         return this.datahead;
      },
      rshuju() {
         if (!this.data) {
            return [];
         }
         let k = JSON.parse(JSON.stringify(this.data));
         let kk = k.map(element => {
            return this.$chuli([element]);
         });
         return kk;
      },
      columns() {
         // 确保this.data[0]是一个对象
         if (typeof this.data[0] === "object" && this.data[0] !== null) {
            // 获取对象的所有属性名
            let keys = Object.keys(this.data[0]);
            // 筛选出包含"col"的属性名
            let colKeys = keys.filter(key => key.includes("col"));
            // 获取包含"col"的属性个数
            let colCount = colKeys.length;

            // 基于colCount来返回你需要的值
            // 这里的逻辑可以根据你的需求自定义
            // 例如，如果属性名中包含"col"的个数大于等于1, 返回24，否则返回12
            return colCount;
         }
         // 如果this.data[0]不是对象或为空，则可以返回一个默认值或处理错误
         return 0; // 或者任何适合的默认值
      },
      headerLabels() {
         return this.generateHeaderLabels();
      },
      tableStyle() {
         return {
            width: "100%",
            borderCollapse: "collapse",
            borderRadius: "4px",
            border: "1px solid #CCE5FF",
            tableLayout: "fixed",
            overflow: "hidden"
         };
      },
      firstColumnWidth() {
         return this.styleType === 1 ? "290px" : "115px";
      },
      columnWidth() {
         if (this.styleType === 1) {
            return `110px`;
         } else {
            return `110px`;
         }
      },
      tableWidth() {
         // Assuming the parent container's width is the table's width
         // This is a placeholder value, adjust as needed
         return 1000; // px
      }
   },
   methods: {
      adddw(e) {
        let meiju = [{name:'温度',dw:"摄氏度"},{name:'湿度',dw:"RH"},{name:'风速',dw:"m/s"},{name:'紫外辐射',dw:"W/㎡"}
        ,{name:'大气压力',dw:"hpa"},{name:'噪音',dw:"(dB)"},{name:'云量统计',dw:"(%)"},{name:'太阳辐射',dw:"(MJ·m   ·d    )"}
        ,{name:'降雨量',dw:"（mm/h）"},{name:'negative_ion',dw:"微克/立方体"},{name:'负氧离子浓度',dw:"微克/立方体"},
        {name:'PM2.5',dw:"μg/m³"}, {name:'PM10',dw:"μg/m³"}, {name:'O2',dw:"( %VOL )"},{name:'CO',dw:"(ppm)"},{name:'H2',dw:"(ppm)"}
        ,{name:'NH3',dw:"(ppm)"},{name:'H2S',dw:"(ppm)"},{name:'C2H4',dw:"(ppm)"},{name:'HCHO',dw:"(ppm)"},{name:'O3',dw:"(ppm)"}
        ]	
      return  meiju.find(item=>e==item.name)? e+"<br>"+meiju.find(item=>e==item.name)['dw'] :e
      },
      getCurrentTimestamp() {
         return (
            new Date().getTime() +
            (Math.floor(Math.random() * (100 - 20000 + 1)) + 20000)+Math.random()
         );
      },
      nha(value) {
         if (!isNaN(value) && !isNaN(parseFloat(value))) {
            // 如果可以，转换为数字并保留两位小数
            return parseFloat(parseFloat(value).toFixed(2));
         } else {
            // 如果不可以，保留原样
            return value;
         }
      },
      generateHeaderLabels() {
         if (this.styleType === 1) {
            // 生成1-12月的标签
            return Array.from({ length: 12 }, (_, i) => `${i + 1}月`);
         } else {
            // 生成0-23小时的标签
            return Array.from({ length: 24 }, (_, i) => `${i}时`);
         }
      },
      getRowColor(index) {
         return "#FFF";
      }
   }
};
</script>

<style scoped>
::-webkit-scrollbar {
   width: 12px;
   height: 12px;
   background-color: rgba(0, 0, 0, 0.2);
}
.haxi {
   margin: 20px 25px;
   border-radius: 4px;
   overflow-x: auto;
}
table {
   border-spacing: 0;
   width: 100%;
}

th,
td {
   text-align: left;
   border: 1px solid #cce5ff;
   padding: 8px;
   text-align: center;
}

th:first-child,
td:first-child {
   border-left: none;
}

th:last-child,
td:last-child {
   border-right: none;
}

tr:last-child td {
   border-bottom: none;
}
</style>
