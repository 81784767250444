import request from '@/utils/request'
let y = {
  type:'1',// 1=月;2=日;3=3小时;4=1小时;5=30分钟;6=10分钟;7=5分钟;8=1分钟
  num:12,
  startime:'2024-3-28',
  position:'',//通过设备列表接口 获取position
  field:''
  //‘NH3’;’H2S’;’CO’;’O2’;’H2’;’C2H4’;’HCHO’;’O3’;’SO2’;
  //’NO2’’HCL’;’HCN’;’CL2’;’HF’;’ETO_VOC’;’PH3’;’DG01’;’PM2_5’;’formaldehyde’;’TVOC’;’CO2’;’negative_ion’;
}

export function getqixiang(data) {
  return request({
    url: 'index/weatherparameters',data,
    method: 'post',
  })
}

export function yunliang(data) {
  return request({
    url: 'index/skyparameterssky',data,
    method: 'post',
  })
}


export function gettiankong(data) {
  return request({
    url: 'index/skyparameters',data,
    method: 'post',
  })
}
export function getkongqi(data) {
  return request({
    url: 'index/airqualityparameters',data,
    method: 'post',
  })
}
export function getzwx(data) {
  return request({
    url: 'index/skyparametersuv',data,
    method: 'post',
  })
}
export function shebeilist(params) {
  return request({
    url: 'index/equipment',params,
    method: 'get',
  })
}