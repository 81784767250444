import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import echarts from 'echarts'
Vue.prototype.$echarts = echarts;
// plugins
import '@/plugins';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import componts from '@/components'
Vue.use(componts)
// 样式
function unixTimestampToDate(timestamp, precision) {
  if (!timestamp) return "";
  // 将Unix时间戳转换为毫秒，并创建一个新的日期对象
  const date = new Date(timestamp * 1000);

  // 获取年月日时分秒
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const seconds = ('0' + date.getSeconds()).slice(-2);

  // 根据precision参数，决定返回哪种精度的日期
  if (precision == 1) {
    return `${year}-${month}`;
  } else if (precision == 2) {
    return `${month}-${day}`;
  } else {
    // return `${day}日 ${hours}:${minutes}:${seconds}`;
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
}


import '@/style/common.scss'
Vue.prototype.suiji = function (count, xx = 200, yy = 25) {
  var data = [];
  for (var i = 0; i < count; i++) {
    // 生成在yy和xx范围内的随机数，包含yy和xx
    data.push(Math.floor(Math.random() * (xx - yy + 1)) + yy);
  }
  return data;
}
Vue.prototype.myfind= function (arr, key) {
  return arr.find(item => item.name === key);
}
//   names,// td第一列
// newData, //td
// theas //th
Vue.prototype.$chuli2 = function (data,x=2) {

  const names = data.reduce((acc, item) => {
    if (item.name) {
      acc.push((item.name));
    }
    return acc;
  }, []);  // td第一列

  // 删除第一项数据后的新数组
  const newData = data.slice(1).map(item => {
    // 如果item是数组，则复制并颠倒数组；否则直接返回item
    return Array.isArray(item) ? [...item] : item;
  }); //td
// console.log('data :>> ', data);
  let theas = {};
  Object.keys(data[0]).forEach(item=>{
    theas[item] = unixTimestampToDate(data[0][item],x)
  
  })
  
// console.log('theas :>> ', theas);
  // // 判断 theas 是否为数组，如果是，则复制并颠倒数组；否则直接使用 theas
  // if (Array.isArray(theas)) {
  //   theas = [...theas]
  // }

  return {
    names, // td第一列
    newData, //td
    theas, //th
  };
}
Vue.prototype.churi = function (inputDate, daysArray) {
  // 将输入的日期字符串转换为Date对象
  const input = new Date(inputDate);
  const inputYear = input.getFullYear();
  const inputMonth = input.getMonth() + 1; // JS中月份是从0开始的
  const inputDay = input.getDate();

  // 用于存储转换结果的数组
  const formattedDates = daysArray.map((dateString) => {
    // 从数组中的字符串中提取月份和日份
    const [monthString, dayString] = dateString.split('-');
    const month = parseInt(monthString, 10);
    const day = parseInt(dayString, 10);

    // 初始化年份为输入年份
    let year = inputYear;

    // 判断当前处理的月日是否大于输入日期的月日
    if (month > inputMonth || (month === inputMonth && day > inputDay)) {
      // 如果是，说明是上一年的日期
      year -= 1;
    }

    // 格式化年份、月份和日份，确保它们都是两位数字
    const formattedYear = year.toString();
    const formattedMonth = monthString.padStart(2, '0');
    const formattedDay = dayString.padStart(2, '0');

    // 返回YYYY/MM/DD格式的字符串
    return `${formattedMonth}/${formattedDay}`;
  });

  // 返回包含所有格式化日期的数组
  return formattedDates;
}

// 表格data
Vue.prototype.$chuli = function (data) {
  // 使用 reduce 进行数组遍历
  const result = data.reduce((acc, currentObj) => {
    // 对每个对象，使用 Object.keys 获取所有键，然后过滤出包含 "col" 的键
    Object.keys(currentObj)
      .filter(key => key.includes('col'))
      .forEach(colKey => {
        // 获取键对应的值
        const value = currentObj[colKey];
        // 检查值是否可以转换为数字
        if (!isNaN(value) && !isNaN(parseFloat(value))) {
          // 如果可以，转换为数字并保留两位小数
          acc.push(parseFloat(parseFloat(value).toFixed(2)));
        } else {
          // 如果不可以，保留原样
          acc.push(value);
        }
      });
      // console.log('acc :>> ', acc.reverse());
      acc.reverse()

    return acc;
  }, []); // 初始累积器为一个空数组
  // 返回处理后的数组
  return result;
}
 Vue.prototype.suiji2 = function (num) {
  // 创建一个空对象
  const dataObject = {};

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  for (let i = 1; i <= num; i++) {
    dataObject[`col${i}`] = getRandomInt(1, 100);
  }

  // 返回填充后的对象
  return dataObject;
}
Vue.prototype.shier =[
  "1月",
  "2月",
  "3月",
  "4月",
  "5月",
  "6月",
  "7月",
  "8月",
  "9月",
  "10月",
  "11月",
  "12月"
]
Vue.config.productionTip = false
Vue.prototype.gettime = function(includeTime = false) {
  const now = new Date();
  
  const year = now.getFullYear();
  // 月份从0开始，因此加1
  const month = now.getMonth() + 1;
  const day = now.getDate();
  
  // 格式化月份和日期 - 当它们小于10时在前面添加一个'0'
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;
  
  if (includeTime) {
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    
    // 格式化小时、分钟和秒 - 当它们小于10时在前面添加一个'0'
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    
    return `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${year}-${formattedMonth}-${formattedDay}`;
  }
}
new Vue({
  router,
  store,
  
  render: h => h(App)
}).$mount('#app')
