import Ttabletop from '@/components/tabletop.vue'
import Ttable from '@/components/table.vue'

export default {
  install(Vue) {
    Vue.component('Ttabletop', Ttabletop)
    Vue.component('Ttable', Ttable)

  }
}
