import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue'),
  },
  {
    path: '/tiankong',
    name: 'tiankong',
    component: () => import('../views/tiankong.vue'),
  }, {
    path: '/kongqi',
    name: 'kongqi',
    component: () => import('../views/kongqi.vue'),
  },
  {
    path: '/qixiang',
    name: 'qixiang',
    component: () => import('../views/qixiang.vue'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

export default router
