<template>
   <div class="ttops">
      <div class="zuo">{{title}}</div>
      <div class="you" @click="moban">
         <i class="el-icon-download"></i>
         下载{{!istu? '表格':'图片'}}
         <div class="xian"></div>
      </div>
   </div>
</template>

<script>
export default {
   props: {
      istu: {
         default: false,
         type: Boolean || String
      },
      title: {
         default: "默认tit",
         type: String
      },
      mydata: {
         default: () => {
            return {};
         },
         type: [Object, Array]
      },
      myxz: {
         default: () => [],
         type: Array
      },
      isduo: {
         default: false,
         type: Boolean || String
      }
   },
   created() {
      ////console.log("this.title :>> ", this.title);
   },
   data() {
      return {};
   },
   methods: {
      moban() {
         function formatUnit(input) {
            // 用'dw:'拆分输入字符串
            const parts = input.split("dw:");
            // 如果存在'dw:'，则处理字符串
            if (parts.length === 2) {
               const name = parts[0].trim(); // 获取名称并去除两端空格
               const unit = parts[1].trim(); // 获取单位并去除两端空格
               // 确保单位被正确的括号包裹
               const formattedUnit =
                  unit.startsWith("(") && unit.endsWith(")")
                     ? unit
                     : `(${unit})`;
               return `${name}${formattedUnit}`; // 返回格式化后的字符串
            }
            // 如果不存在'dw:'，直接返回原始字符串
            return input;
         }

         let names = this.mydata["names"];
         let data = this.mydata["newData"];
         let tou = this.$chuli([this.mydata["theas"]]);
         tou.unshift(" ");
         let headers = {
            设备名称: "name",
            所属区域: "location_id",
            所属机构: "unit_id"
         };
         let meiju = [
            { name: "温度", dw: "摄氏度" },
            { name: "湿度", dw: "RH" },
            { name: "风速", dw: "m/s" },
            { name: "紫外辐射", dw: "W/㎡" },
            { name: "大气压力", dw: "hpa" },
            { name: "噪音", dw: "dB" },
            { name: "云量统计", dw: "(%)" },
            { name: "太阳辐射", dw: "(MJ·m   ·d    )" },
            { name: "降雨量", dw: "（mm/h）" },
            { name: "negative_ion", dw: "微克/立方体" },
            { name: "负氧离子浓度", dw: "微克/立方体" },
            { name: "PM2.5", dw: "μg/m³" },
            { name: "PM10", dw: "μg/m³" }
         ];
         headers = tou.reduce((obj, item) => {
            obj[item.toString()] = item.toString();
            return obj;
         }, {});
         if (this.isduo) {
            data = data.filter(item => this.myxz.includes(item.name));
         }

         let datas = data.map((item, index) => {
            let k = this.$chuli([item]);
            let jk = meiju.find(items => item.name == items.name)
               ? item.name +
                 "dw:" +
                 meiju.find(items => item.name == items.name)["dw"]
               : item.name;

            let ks = formatUnit(jk);
            //console.log('jk :>> ', ks==jk);
            k.unshift(ks);
            return k;
         });
         //console.log('datas,tou :>> ', datas,tou);
         import("@/vendor/Export2Excel").then(excel => {
            excel.export_json_to_excel({
               header: Object.keys(headers), // 表头 必填
               data: datas, // 具体数据 必填
               filename: "excel-list", // 非必填
               autoWidth: true, // 非必填
               bookType: "xlsx" // 非必填
            });
         });
      }
   }
};
</script>

<style lang="scss" scoped>
.zuo {
   font-family: Source Han Sans CN;
   font-weight: bold;
   font-size: 24px;
   color: #ffffff;
}
.you {
   cursor: pointer;
   color: #ffffff;
   font-family: Source Han Sans CN;
   font-weight: 500;
   font-size: 20px;
   color: #ffffff;
   // line-height: 117px;
}
.xian {
   height: 1px;
   background: #ffffff;
}
.ttops {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-left: 19px;
   padding-right: 45px;
   height: 54px;
   // border-radius: 20px;
   border-top-left-radius: 20px;
   border-top-right-radius: 20px;

   background: #1988ff;
}
</style>